import React from 'react'
import {
  Box,
  Heading,
  useColorModeValue,
  Text,
  Button,
  Image,
  Flex
} from '@chakra-ui/react'
import { GiForklift, GiCargoCrane, GiTruck } from "react-icons/gi"

import guindaste from '../images/caminhao taurus.jpg'
import empilhadeira from '../images/empilhadeira.jpg'
import caminhao from '../images/caminhao estrada.jpg'

const Service = ({image, altText, title, text, buttonIcon, buttonText}) => {
  return(
    <Box
      bg={useColorModeValue('white', 'gray.900')}
      boxShadow={'2xl'}
      rounded={'lg'}
      textAlign={'center'}
      m={2}
      id='servicos'
      >
      <Image
        rounded="3rem"
        h={{base: '600px', lg: '350px'}}
        w={{base: '600px', lg: '350px'}}
        fit={'cover'}
        src={image}
        alt={altText}
        display={'inline-flex'}
        mb={6}
        p={3}
      />
      <Heading fontSize={'2xl'} mb='8'>
        {title}
      </Heading>
      <Text
        textAlign={'center'}
        color={useColorModeValue('gray.700', 'gray.400')}
        px={3}
        mb={8}>
          {text}
      </Text>
      <Button 
        as="a"
        mb={6}
        colorScheme={'orange'}
        rightIcon={buttonIcon}
        href={'#contato'}
      >
        {buttonText}
      </Button>
    </Box>
  )
}

export default function Services() {
  return(
    <Box>
      <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }} align='center' mb="10">
        Serviços
      </Heading>
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        justify={{ base: 'center', lg: 'space-around' }}
        align={'center'}
      >
        <Service 
          image={empilhadeira}
          altText={'Alt'}
          title={'Armazenagem de mercadorias'}
          text={'Realiza o recebimento, armazenanamento e repaletização de cargas. A empresa conta com barracões para a estocagem de materiais, equipamentos como docas, empilhadeiras, aplicadoras de filme stretch para melhor execução do trabalho.'}
          buttonIcon={<GiForklift/>}
          buttonText={'Contratar serviço de armazenagem'}
        />
        <Service 
          image={guindaste}
          altText={'Alt'}
          title={'Serviço caminhão Munck'}
          text={'Trabalha com o serviço de içamento de cargas e materiais com caminhões guindaste / munck. A empresa possui equipamentos certificados, compatíveis com as cargas e contam com controle remoto para melhor operação e aumento da segurança'}
          buttonIcon={<GiCargoCrane/>}
          buttonText={'Contratar serviço de munck'}
        />
        <Service 
          image={caminhao}
          altText={'Alt'}
          title={'Transporte de mercadorias'}
          text={'Faz o transporte de mercadorias com veículos com guindaste / munck. Os materiais são carregados em nossa empresa com o auxílio de docas e empilhadeiras e transportados por nossa frota composta de veículos atualizados e implementos compatívies com a operação'}
          buttonIcon={<GiTruck/>}
          buttonText={'Contratar serviço de transporte'}
        />
      </Flex>
    </Box>
  )
}