
import React from 'react';
import {
  Flex,
  Heading,
  Stack,
  Box,
  FormControl,
  useColorModeValue,
  FormLabel,
  Input,
  Button,
  Textarea,
  Text
} from '@chakra-ui/react'

import { MdEmail, MdPhoneInTalk,  MdLocationOn } from "react-icons/md";

const ContactItem = ({icon, title, text}) => {
  return(
    <Box  
      spacing={4}
      bg={useColorModeValue('white', 'gray.700')}
      rounded={'xl'}
      boxShadow={'lg'}
      p={8}
      m={3}
    >
      <Flex 
        direction='row'
        align='center'
        mb={2}
      >
        {icon}
        <Text fontSize='4xl' ml={3}>
          {title}
        </Text>
      </Flex>
      <Text 
        fontSize='2xl'
        ml={2}
      >
        {text}
      </Text>
    </Box>
  )
}


export default function Contact() {
  return(
    <Flex
      w={'100%'}
      align={'center'}
      justify={'center'}
      my={6}
      id='contato'
      >
      <Stack>
        <Stack mt={8} align="center">
          <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }} align='center'>
            Contato
          </Heading>
        </Stack>
        <Flex 
          direction={{base:'column', lg:'row'}} 
          align={'center'} 
          justify={{base: 'center', md:'space-between'}}
          >
          <Stack
            spacing={4}
            bg={useColorModeValue('white', 'gray.700')}
            rounded={'xl'}
            boxShadow={'lg'}
            p={12}
            m={12}
            minW={'40vw'}
            as='form'
            method='post'
            netlify-honeypot='bot-field' 
            data-netlify='true' 
            name='contato-taurus'
            onSubmit='submit'
            >
            <Input 
              type="hidden"
              name="bot-field"
              value="contato-taurus"
            />
            <FormControl
              id="name" 
              colorScheme={"brand"}
              isRequired 
              >
              <FormLabel>Nome</FormLabel>
              <Input 
                placeholder="Seu nome"
                _placeholder={{ color: 'gray.500' }}
                type="text"
                name="name"
              />
            </FormControl>
            <FormControl 
              id="email"
              colorScheme={"orange"}
              isRequired
              >
              <FormLabel>Endereço de email</FormLabel>
              <Input
                placeholder="exemplo@gmail.com"
                _placeholder={{ color: 'gray.500' }}
                type="email"
                name="email"
              />
            </FormControl>
            <FormControl id="phone">
              <FormLabel>Telefone</FormLabel>
              <Input 
                placeholder="(49) 9999-9999"
                _placeholder={{ color: 'gray.500' }}
                type="text"
                name="phone"
              />
            </FormControl>
            <FormControl 
              id="message" 
              colorScheme={"orange"}
              isRequired
              >
              <FormLabel>Mensagem</FormLabel>
              <Textarea 
                placeholder="A mensagem que você gostaria de nos enviar"
                _placeholder={{ color: 'gray.500' }}
                name="message"
              />
            </FormControl>
            <Stack spacing={6}>
              <Button
                type='submit'
                bg={'orange.400'}
                color={'white'}
                _hover={{
                  bg: 'orange.500',
                }}>
                Enviar mensagem
              </Button>
            </Stack>
          </Stack>
          <Flex 
            align={'stretch'} 
            justify={'space-between'} 
            direction={'column'}
            >
            <ContactItem 
              icon={<MdLocationOn size='30px'/>}
              title={'Localizacao'}
              text={'Rodovia BR-282, KM 412'}
            />
            <ContactItem 
              icon={<MdPhoneInTalk size='30px'/>}
              title={'Telefone'}
              text={'(49) 3525 1031'}
            />
            <ContactItem 
              icon={<MdEmail size='30px'/>}
              title={'Email'}
              text={'taurus@tauruslogistica.com.br'}
            />
          </Flex>
        </Flex>
      </Stack>
    </Flex>
  )
}