import React from 'react';

import SEO from '../components/seo';
import Hero from '../components/hero';
import Features from '../components/feature';
import Services from '../components/services';
import Contact from '../components/contact';
import Layout from '../components/layout';

export default function IndexPage() {
  return(
    <Layout>
      <SEO title="Home" />
      <Hero />
      <Features />
      <Services />
      <Contact />
    </Layout>
  )
}