import React from 'react'
import {
  Box,
  Flex,
  Heading,
  Text,
  useBreakpointValue,
  Image,
  Button
} from '@chakra-ui/react';
import {FaRegComments} from 'react-icons/fa';
import { AnchorLink } from "gatsby-plugin-anchor-links";

import heroImage from '../images/caminhao taurus.jpg';

export default function Hero() {
  return(
    <Flex direction={{base:"column", lg:"row"}} minH={{ md:"80vh"}} align={"center"} justify={"center"} mb={10}>
      <Box align="center" mt={10}>
        <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }} my={6}>
          <Text
            as={'span'}
            position={'relative'}
            _after={{
              content: "''",
              width: 'full',
              height: useBreakpointValue({ base: '20%', md: '30%' }),
              position: 'absolute',
              bottom: 1,
              left: 0,
              bg: 'orange.400',
              zIndex: -1,
            }}>
            Taurus Logística
          </Text>
          <br />{' '}
        </Heading>
        <Text fontSize={{ base: 'sm', md: "md", lg: 'lg' }} color={'gray.500'} mb={4}>
          <Text>
            Armazenamento, fracionamento e transporte de produtos
          </Text>
          <Text>
            Serviços de guindaste, munck e movimentação de cargas
          </Text>
          <Text>
            Locação de caminhão munck com operador
          </Text>
        </Text>
        <AnchorLink
          to="/#contato" title="Contato" 
        >
          <Button
            as="a"
            my={3}
            colorScheme={'orange'}
            rightIcon={<FaRegComments />}
          >
            Entre em contato
          </Button>
        </AnchorLink>
      </Box>
      <Box boxSize={{sm: "sm", md: "md",lg:"xl"}} display={{base:'block', md:'block'}} m={10}>
        <Image 
          src={heroImage}
          size={{md:"30%", lg: "100%"}}
          rounded="1rem" 
          shadow="2xl" 
          fit={'contain'} 
          align={'center'}
        />
      </Box>
    </Flex>
  )
}