
import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  Icon,
  SimpleGrid,
} from '@chakra-ui/react'

import { FcServices, FcConferenceCall, FcMultipleDevices} from 'react-icons/fc'

const Feature = ({ title, text, icon }) => {
  return (
    <Stack align='center' spacing='8'>
      <Flex
        w={16}
        h={16}
        align={'center'}
        justify={'center'}
        color={'white'}
        rounded={'full'}
        bg={'gray.100'}
        mb={1}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={'gray.600'} align="center">{text}</Text>
    </Stack>
  );
};

export default function Features() {
  return(
    <Stack spacing='12' mb='12' align={'center'} justify={'center'} id='diferenciais'>
      <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }} mb={'8'} align='center'>
        Diferenciais
      </Heading>
      <Box p={4}>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          <Feature
            icon={<Icon as={FcServices} w={10} h={10} />}
            title={'Equipamentos'}
            text={
              'Todos os equipamentos da empresa são compatíveis com o serviço prestado. Os veículos são novos e os equipamentos de munck utilizados na elevação de cargas atendem as especificações de capacidade de carga'
            }
          />
          <Feature
            icon={<Icon as={FcConferenceCall} w={10} h={10} />}
            title={'Empresa Especializada'}
            text={
              'Empresa especializada em armazenamento, fracionamento de produtos, operação de muncks veiculares e transportes de cargas. Focado nessa área de atuação, a empresa trabalha com alto nível de serviço e know how'
            }
          />
          <Feature
            icon={<Icon as={FcMultipleDevices} w={10} h={10} />}
            title={'Controles Operacionais e Informatização'}
            text={
              'A empresa conta com controles de nível de serviço, jornada de trabalho, check lists, manutenção preventiva. Todos os equipamentos possuem tecnologia de telemetria e câmeras de fadiga embarcados'
            }
          />
        </SimpleGrid>
      </Box>
    </Stack>
  )
}